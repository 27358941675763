import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { CashIn } from "../Account/CashIn";
import { CashOut } from "../Account/CashOut";
import { Expenses } from "../Account/Expenses";
import { LastApproved } from "../Account/LastApproved";
import { generateLatest } from "../../Services/apis";

const CashBook = () => {
  const [activeTab, setActive] = useState(1);
  const [activeTabItem, setActiveItem] = useState(9);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const generateData = async () => {
    setLoading(true);

    const payload = {
      agent_id: user.id,
    };
    await generateLatest(payload)
      .then((r) => {
        setLoading(false);
        setDetails(r);
      })
      .catch((err) => {
        console.log(err.response);

        setLoading(false);
      });
  };

  useEffect(() => {
    generateData();
  }, []);

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master user Logged";
  }, []);

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt" style={{ marginTop: "-1rem" }}>
                  <h4 className="p-4 commission-head">Cashbook Report</h4>
                </div>
                <div className="cashbook">
                  <ul className="t-header">
                    <li
                      style={{ marginLeft: "-2.5rem" }}
                      onClick={() => setActive(1)}
                      className={
                        activeTab === 1 ? `active tab-item ml` : "tab-item ml"
                      }
                    >
                      Last Approved
                    </li>
                    <li
                      onClick={() => setActive(2)}
                      className={
                        activeTab === 2 ? `active tab-item` : "tab-item"
                      }
                    >
                      Cash In
                    </li>
                    <li
                      onClick={() => setActive(3)}
                      className={
                        activeTab === 3 ? `active tab-item` : "tab-item"
                      }
                    >
                      Cash Out
                    </li>
                    <li
                      onClick={() => setActive(4)}
                      className={
                        activeTab === 4 ? `active tab-item` : "tab-item"
                      }
                    >
                      Expenses
                    </li>
                  </ul>
                  <div className="tab-content">
                    {activeTab === 1 ? <LastApproved /> : ""}
                    {activeTab === 2 ? <CashIn /> : ""}
                    {activeTab === 3 ? <CashOut /> : ""}
                    {activeTab === 4 ? <Expenses /> : ""}
                  </div>

                  <div className="table-bottom">
                    <ul className="t-header">
                      <li
                        className={
                          activeTabItem === 9 ? `active tab-item` : "tab-item"
                        }
                      >
                        Approved
                      </li>
                    </ul>
                    <table
                      style={{
                        borderWidth: "0px",
                        borderStyle: "none",
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tr
                        style={{
                          borderWidth: "0px",
                          borderBottom: "1px solid #f4f4f4",
                          width: "100%",
                          borderCollapse: "collapse",
                          background: "transparent",
                          color: "#4c4c4c",
                          textAlign: "center",
                        }}
                      >
                        <th align="center" scope="col">
                          Date
                        </th>
                        <th align="center" scope="col">
                          Opening
                        </th>
                        <th align="center" scope="col">
                          Normal Sales
                        </th>
                        <th align="center" scope="col">
                          Virtual Sales
                        </th>
                        <th align="center" scope="col">
                          Normal Payout
                        </th>
                        <th align="center" scope="col">
                          Cash In
                        </th>
                        <th align="center" scope="col">
                          Cash Out
                        </th>
                        <th align="center" scope="col">
                          Expenses
                        </th>
                        <th align="center" scope="col">
                          Closing
                        </th>
                      </tr>
                      <tbody>
                        {/* <tr className="dgItemStyle">
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                          <td align="center"> Item</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashBook;
