import { LOADING, SHOW_MODAL } from "../../Redux/types";
import {  } from "../../Services/apis";
import { createWithdraw } from "../../Redux/actions/onlineWithdraw";
import { useState } from "react";


export const WithdrawalConfirmModal = ({ data, dispatch }) => {
  const [loading, setLoading] = useState(false);
  
  const approve = () => {
    setLoading(true);
    dispatch(createWithdraw({ withdraw_code: data.withdraw_code }));
  };

  return (
    <div className="withdraw">
    <form className="new-entry">
      <div className="entry-field">
        <label>Customer ID</label>
        <input
          type="text"
          name="opening"
          value={data.user.username}
          disabled={true}
        />
      </div>
      <div className="entry-field">
        <label>Withdrawal Amount</label>
        <input
          type="number"
          name="opening"
          value={data.amount}
          disabled={true}
        />
      </div>
      {/* <div className="entry-field">
        <label>Code</label>
        <input
          type="text"
          name="opening"
          value={inputObject.withdrawCode}
          disabled={true}
        />
      </div> */}
      <div className="entry-field">
        <label>Your Balance</label>
        <input
          type="number"
          name="opening"
          disabled={true}
          value={data.user.available_balance}
        />
      </div>
    </form>
    <div className="expense-btn">
      <input
        type="button"
        name="expenses"
        value="PAY CUSTOMER"
        className="btn-green"
        disabled={loading}
        onClick={() => approve()}
      />
      <input
        type="button"
        name="expenses"
        value="CANCEL"
        className=" btn-red"
        onClick={() => dispatch({type: SHOW_MODAL, payload: null})}
      />
    </div>
  </div>
  );
}