import React, { useState } from "react";
import { formatNumber } from "../Utils/helpers";
import { ExportCSV } from "./Download";

const CommissionsTable = ({ data, currency }) => {
  return (
    <div className="table-bottom sales-table">
      <table
        className="new-table"
        style={{
          width: "100%",
        }}
      >
        <tr
          style={{
            borderWidth: "0px",
            width: "100%",
            // borderCollapse: "collapse",
            border: "none",
            background: "transparent",
            color: "#4c4c4c",
            textAlign: "center",
          }}
        >
          <th align="center" scope="col" style={{ padding: ".7rem" }}>
            Channel Name
          </th>
          <th align="center" scope="col">
            Settled Bets
          </th>
          <th align="center" scope="col">
            Stake ({currency})
          </th>

          <th align="center" scope="col">
            Winnings ({currency})
          </th>
          <th align="center" scope="col">
            Gross Profit ({currency})
          </th>
          <th align="center" scope="col">
            Commission ({currency})
          </th>
          {/* <th align="center" scope="col">
            Bonus {currency}
          </th> */}
        </tr>
        {/* <tbody> */}
        {/* {data.map((item, index) => ( */}
        <React.Fragment>
          <tr className="dgItm">
            <td align="center" style={{ textTransform: "uppercase" }}>
              SPORT
            </td>
            <td align="center">{data?.sports?.settled_bets}</td>

            <td align="center">
              {data?.sports?.stake ? formatNumber(data?.sports?.stake) : 0}
            </td>
            <td align="center">
              {data?.sports?.winnings
                ? formatNumber(data?.sports?.winnings)
                : 0}
            </td>
            <td align="center">
              {formatNumber(
                data?.sports?.gross_profit ? data?.sports?.gross_profit : 0
              )}
            </td>

            <td align="center">
              {formatNumber(
                data?.sports?.commission ? data?.sports?.commission : 0
              )}
            </td>
          </tr>
          <tr className="dgItm">
            <td align="center" style={{ textTransform: "uppercase" }}>
              VIRTUAL
            </td>
            <td align="center">{data?.virtual?.settled_bets}</td>

            <td align="center">
              {data?.virtual?.stake ? formatNumber(data?.virtual?.stake) : 0}
            </td>
            <td align="center">
              {data?.virtual?.winnings
                ? formatNumber(data?.virtual?.winnings)
                : 0}
            </td>
            <td align="center">
              {formatNumber(
                data?.virtual?.gross_profit ? data?.virtual?.gross_profit : 0
              )}
            </td>
            <td align="center">
              {formatNumber(
                data?.virtual?.commission ? data?.virtual?.commission : 0
              )}
            </td>
            {/* <td align="center">
              {formatNumber(data?.sports?.bonus ? data?.sports?.bonus : 0)}
            </td> */}
          </tr>
        </React.Fragment>
        {/* ))} */}
        <tr className="total">
          <td align="center" style={{ textTransform: "uppercase" }}>
            GRAND TOTAL
          </td>
          <td align="center">{data?.grand_total?.settled_bets}</td>

          <td align="center">{formatNumber(data?.grand_total?.stake)}</td>
          <td align="center">
            {data?.grand_total?.winnings
              ? formatNumber(data?.grand_total?.winnings)
              : 0}
          </td>
          <td align="center">
            {formatNumber(
              data?.grand_total?.gross_profit
                ? data?.grand_total?.gross_profit
                : 0
            )}
          </td>
          <td align="center">
            {formatNumber(
              data?.grand_total?.commission ? data?.grand_total?.commission : 0
            )}
          </td>
          {/* <td align="center">
            {formatNumber(
              data?.grand_total?.commission ? data?.grand_total?.commission : 0
            )}
          </td> */}
        </tr>
      </table>
      <div
        className="date-group "
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
          paddingTop: "2rem",
          borderTop: "1px solid #ff2222",
        }}
      >
        <ExportCSV csvData={data} fileName="All Sales" file="PDF" />
        <ExportCSV csvData={data} fileName="All Sales" file="CSV" />
      </div>
    </div>
  );
};

export default CommissionsTable;
