import React, { useState } from "react";
import { formatNumber } from "../Utils/helpers";

import { ExportCSV } from "./Download";

const ExpandableTable = ({ data, currency }) => {
  return (
    <div className="table-bottom sales-table">
      <table
        className="new-table"
        style={{
          width: "100%",
        }}
      >
        <tr
          style={{
            borderWidth: "0px",
            width: "100%",
            // borderCollapse: "collapse",
            border: "none",
            background: "transparent",
            color: "#4c4c4c",
            textAlign: "center",
          }}
        >
          <th align="center" scope="col" style={{ padding: ".7rem" }}>
            User
          </th>
          <th align="center" scope="col">
            # of Bets
          </th>
          <th align="center" scope="col">
            Running Bets
          </th>
          <th align="center" scope="col">
            Settled Bets
          </th>
          <th align="center" scope="col">
            Stake Bets({currency})
          </th>
          <th align="center" scope="col">
            Winnings({currency})
          </th>
        </tr>
        {/* <tbody> */}
        {data?.data.map((item, index) => (
          <React.Fragment key={index}>
            <tr className="dgItm" key={index}>
              <td align="center" style={{ textTransform: "uppercase" }}>
                {item.username}
              </td>
              <td align="center">
                {item?.bets?.no_of_bets ? item?.bets?.no_of_bets : 0}
              </td>
              <td align="center">
                {item.bets?.running_bets ? item.bets?.running_bets : 0}
              </td>
              <td align="center">
                {item.bets?.settled_bets ? item.bets?.settled_bets : 0}
              </td>
              <td align="center">
                {item?.bets?.stake ? formatNumber(item?.bets?.stake) : 0}
              </td>
              <td align="center">
                {item?.bets?.winnings ? formatNumber(item?.bets?.winnings) : 0}
              </td>
            </tr>
          </React.Fragment>
        ))}
        <tr className="total">
          <td align="center" style={{ textTransform: "uppercase" }}>
            Total
          </td>
          <td align="center">{data?.grand_total?.no_of_bets}</td>
          <td align="center">{data?.grand_total?.running_bets}</td>
          <td align="center">{data?.grand_total?.settled_bets}</td>
          <td align="center">{formatNumber(data?.grand_total?.stake)}</td>
          <td align="center">{formatNumber(data?.grand_total?.winnings)}</td>
        </tr>
      </table>
      <div
        className="date-group "
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
          paddingTop: "2rem",
          borderTop: "1px solid #ff2222",
        }}
      >
        <ExportCSV csvData={data} fileName="All Sales" file="PDF" />
        <ExportCSV csvData={data} fileName="All Sales" file="CSV" />
      </div>
    </div>
  );
};

export default ExpandableTable;
