import React from "react";
import { updateComboWinningsFromTotal } from "../../../Redux/actions";
import { SET_COUPON_DATA } from "../../../Redux/types";
import CouponCalculation from "../../../Utils/CouponCalculation";
import { formatNumber } from "../../../Utils/helpers";

export const Combined = ({ couponData, dispatch, globalVar, bonusList }) => {
    const couponCalculation = new CouponCalculation();

    const minMaxWin = (e, combo, i) => {
        let val = e.target.value
        let coupondata = { ...couponData };

        if (val !== '' && val !== 0) {
            combo.Stake = val;
            combo.checked = true;
            if (!coupondata.Groupings) {
                coupondata.Groupings = [combo];
            } else {
                coupondata.Groupings.push(combo);
            }

            const calculatedCoupon = couponCalculation.calcPotentialWins(coupondata, bonusList);
            coupondata = couponCalculation.updateFromCalculatedCoupon(coupondata, calculatedCoupon, globalVar, bonusList);
            // update combos with max win
            coupondata.combos.forEach(combo => {
                for (let i = 0; i < coupondata.Groupings.length; i++) {
                    if (combo.Grouping === coupondata.Groupings[i].Grouping) {
                        combo.minWIn = coupondata.Groupings[i].minWin;
                        combo.maxWin = coupondata.Groupings[i].maxWin;
                        combo.Stake = coupondata.Groupings[i].Stake;
                    }
                }
            })


            let total = 0;

            for (let x = 0; x < coupondata.combos.length; x++) {
                // console.log(coupondata.combos[x].minOdds)
                if (coupondata.combos[x].Stake !== undefined && coupondata.combos[x].Stake !== '') {
                    total += parseFloat(coupondata.combos[x].Combinations) * parseFloat(coupondata.combos[x].Stake)
                }
            }

            coupondata.totalStake = total;
            coupondata.exciseDuty = coupondata.totalStake * 0 / 100;
            coupondata.stake = coupondata.totalStake - coupondata.exciseDuty;

            return dispatch({ type: SET_COUPON_DATA, payload: coupondata });

        }
    }

    function comboName(len) {
        switch (len) {
            case 1:
                return ' Singles'
            case 2:
                return ' Doubles'
            case 3:
                return ' Trebles'
            default:
                return +' ' + len + ' folds'
        }
    }

    return (
        <div className="divCpnTipoCnt">
            <div className="CpnTipoSisRiepHdr">
                <span className="TSTipo">Type</span>
                <span className="TSNComb">N. Comb.</span>
                <span className="TSTxt">Amount</span>
            </div>
            <div className="CpnTipoRiepRagg" id="sisPlayAll">
                <div className="RiepSX"><a onclick="selRaggr(event);">Play all</a></div>
                <div className="RiepDX">
                    <span id="spanBadGiocaSuTutte">
                        <span style={{ visibility: 'hidden', display: 'none' }} />
                        <img src="../App_Themes/Bet9jaShop_1/images/Error_small.png" align="absmiddle" style={{ borderWidth: '0px', visibility: 'hidden' }} />
                    </span>
                    <span style={{ display: 'none' }}>
                        <input type="text" id="txtGiocaSuTutteValuta" onkeyup="giocaTutteValuta()" className="TextBoxValuta" style={{ width: '35px' }} maxlength="5" />
                    </span>
                    <span id="spanGiocaSuTutte">
                        <input type="text" id="txtGiocaSuTutte" className="TextBox" style={{ width: '35px' }} maxlength="5" />
                    </span>&nbsp;{globalVar.Currency}
                </div>
            </div>
            {couponData.combos.length > 0 && couponData.combos.map((combo, c_index) =>
                <div className="CpnTipoSisRiep" key={`combo-${c_index}`}>
                    <span className="TSChk" id="spanChkQuota1">
                        <input
                            name={`comb_${c_index}`}
                            id={`comb_${c_index}`}
                            checked={combo.checked}
                            onChange={() => dispatch(updateComboWinningsFromTotal())}
                            type="checkbox"
                        />
                    </span>

                    <span id="" title="Singles" className="TSTipo">{comboName(combo.Grouping)}</span>
                    <span className="TSNComb" id="spanLbl2Sistema1"><span>{combo.Combinations}</span></span>
                    <span id="spanTxtSisTot1">
                        {/* <img src="../App_Themes/Bet9jaShop_1/images/Error_small.png" align="absmiddle" style="border-width:0px;visibility: hidden;" /> */}
                        {/* <img src="../App_Themes/Bet9jaShop_1/images/blank.gif" height="1" width="3" /> */}
                    </span>
                    <span className="TSTxt">
                        <input
                            name={`imp_comb_${c_index}`}
                            type="text"
                            maxLength="5"
                            className="TextBox"
                            value={combo.Stake}
                            style={{ width: '35px' }}
                            onChange={(e) => minMaxWin(e, combo, c_index)}
                        />&nbsp;{globalVar.Currency}
                    </span>
                    <span className="TSVinLbl">Pot. wins</span>
                    <span className="TSVin" id="spanTxtSisVin1" style={{ float: 'right' }}>
                        <span>{combo.minWin ? formatNumber(combo.minWin) : 0} / {combo.maxWin ? formatNumber(combo.maxWin) : 0}</span>&nbsp;{globalVar.Currency}
                    </span>
                </div>
            )}
            <div>
                <div class="CpnTipoRiep stake">
                    <div class="RiepSX">Total Stake</div>

                    <div class="RiepDX">
                        <span id="spanImportoSis">
                            <input
                                name="s$w$PC$cCouponISBets$txtImportoSis"
                                type="text" maxlength="5"
                                class="TextBox"
                                style={{ width: '45px' }}
                                value={couponData.stake}
                                onChange={(e) => dispatch(updateComboWinningsFromTotal(e.target.value))}
                            />
                        </span>
                        &nbsp;{globalVar.Currency}
                    </div>

                </div>

                <div class="CpnTipoRiepDbl">
                    <div class="RiepSX">Bonus</div>
                    <div class="RiepDX">
                        <div>Min&nbsp;<span id="spanBonusSisMin"><span id="">{formatNumber(couponData.minBonus)}</span></span>&nbsp;{globalVar.Currency}</div>
                        <div>Max&nbsp;<span id="spanBonusSisMax"><span id="">{formatNumber(couponData.maxBonus)}</span></span>&nbsp;{globalVar.Currency}</div>
                    </div>
                </div>

                <div class="CpnTipoRiepDbl grosswin High">
                    <div class="RiepSX">Pot. Winnings</div>
                    <div class="RiepDX">
                        <div>Min&nbsp;<span id="spanVincitaMinSis"><span>{formatNumber(parseFloat(couponData.minWin).toFixed(2))}</span></span>&nbsp;{globalVar.Currency}</div>
                        <div>Max&nbsp;<span id="spanVincitaPotSis"><span>{formatNumber(parseFloat(couponData.maxWin).toFixed(2))}</span></span>&nbsp;{globalVar.Currency}</div>
                    </div>
                    {/* <a title="Update" class="lnkRefresh" href="javascript:__doPostBack('s$w$PC$cCouponISBets$btnCalcolaVincitaSis','')"></a> */}
                </div>

            </div>
        </div>

    )
}
