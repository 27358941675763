import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendFund } from "../Services/apis";
import { toast } from "react-toastify";
import { SHOW_MODAL } from "../Redux/types";

const ElbetFunds = ({ toggle, typeOfTrans }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [inputObject, setObject] = useState({
    amount: "",
  });
  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      username: user?.username,
      amount: inputObject.amount,
      notes: `Credit to Elbet user ${user?.username}  `,
      type: "elbet",
      action: typeOfTrans === "transfer" ? "deposit" : "withdraw",
    };
    sendFund(payload)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          toast.success("Transaction was completed successfully");
        } else {
          toast.success(res.message);
        }
        dispatch({
          type: SHOW_MODAL,
          payload: {
            open: false,
          },
        });
      })
      .catch((err) => {
        toast.error("Internal server error!");
        setLoading(false);
        dispatch({
          type: SHOW_MODAL,
          payload: {
            open: false,
          },
        });
      });
  };

  return (
    <form className="expense-form">
      <div className="expense-input">
        <label>AMOUNT</label>
        <input type="number" onChange={handleChange} name="amount" />
      </div>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value="SUBMIT"
          disabled={inputObject.amount === "" ? true : false}
          className="btn-green"
          onClick={(e) => createUser(e)}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() =>
            dispatch({
              type: SHOW_MODAL,
              payload: {
                open: false,
              },
            })
          }
        />
      </div>
    </form>
  );
};

export default ElbetFunds;
