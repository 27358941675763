import React, { useEffect, useState, useCallback, memo } from "react";
import { fetchGlobalVars, getMonthlyBonus } from "../Services/apis";
import { formatDate } from "../Utils/helpers";
import "../Styles/_bonus.scss";
import "../Styles/_table.scss";
import { BonusInformation } from "./BonusInformation";
import moment from "moment";
import Loader from "react-spinners/BarLoader";
/**
 * packages
 */

const MonthlyBonus = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [payoutDates, setDates] = useState([]);
  const [show, setShow] = useState(false);
  const [currentDate, setCurrentDate] = useState(8);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    setCurrentDate(e.target.value);
  };

  const init = useCallback(() => {
    fetchGlobalVars().then((res) => {
      console.log(res);
      let startD = res?.PowerBonusStartDate;
      let newD = [];
      for (let i = 1; i <= 5; i++) {
        const nextDate = moment(startD).add(28, "days").format("YYYY-MM-DD");
        newD.push({
          from: startD,
          to: nextDate,
          month: moment(startD).format("M"),
        });
        startD = moment(nextDate).add(1, "days").format("YYYY-MM-DD");
      }
      setDates(newD);
    });
  }, []);

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    async function fetchBonus() {
      try {
        setLoading(true);
        if (payoutDates.length !== 0) {
          await getMonthlyBonus(payoutDates[currentDate])
            .then((res) => {
              setLoading(false);
              setData(res.data);
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      } catch (error) {
        console.error(error.response);
      }
    }
    fetchBonus();
  }, [currentDate, payoutDates]);

  return (
    <div
      className="bonus "
      style={{ background: "white", paddingBottom: "5rem" }}
    >
      <div className="content">
        <div className="month-head">
          <h4>Monthly Bonus</h4>
        </div>
        <div className="table-box">
          <div>
            <select
              className="date-box"
              onChange={(e) => handleChange(e)}
              defaultValue="today"
            >
              {payoutDates?.map((item, i) => (
                <option value={payoutDates?.indexOf(item)} key={i}>
                  {formatDate(item?.from, "DD MMMM YYYY") +
                    " - " +
                    formatDate(item?.to, "DD MMMM YYYY")}
                </option>
              ))}
            </select>
          </div>

          <div className="bonus-header">
            <div className="bonus-header-title">
              <span className="bonus-up">
                <span className="span-title">Sports</span>
                <span onClick={() => setShow(!show)}>Bonus Summary</span>
              </span>
              <span className="bonus-rate">
                <span>
                  <span>Bonus rate</span>
                  <h3>{data?.bonus_rate ? data?.bonus_rate : "0"}%</h3>
                </span>
                <span>
                  <span>Total Bonus</span>
                  <h3>{data?.monthlyBonus ? data?.monthlyBonus : "0"}</h3>
                </span>
              </span>
            </div>
            <div className=" bonus-header-stake">
              <span>
                <span>Total Stake (Month)</span>
                <h3 className="value">N{data ? data?.totalStake : 0}</h3>
                <p className="span-title" stlye={{ marginTop: "-.8rem" }}>
                  Target:N
                  {data?.totalStakeTarget ? data?.totalStakeTarget : "0"}
                </p>
              </span>

              <span>
                <span>Coupons Settled(Month)</span>
                <h3 className="value">
                  {" "}
                  {data?.totalTickets ? data?.totalTickets : "0"}
                </h3>
                <p className="span-title" stlye={{ marginTop: "-.8rem" }}>
                  Target Coupons:
                  {" " + data?.totalTicketsTarget
                    ? data?.totalTicketsTarget
                    : "0"}
                </p>
              </span>
              <span>
                <span>Avg. Selection</span>
                <h3 className="value">
                  {data ? data?.averageNoOfSelections : 0}
                </h3>
              </span>
            </div>
          </div>
          <div className="bonus-header">
            <div className="bonus-header-title">
              <div>
                <span className="span-title">Virtual</span>
                <span>Virtual Summary</span>
              </div>
              <div className="bonus-rate">
                <div>
                  <h3>0%</h3>
                </div>
              </div>
            </div>
            <div className=" bonus-header-stake">
              <span>
                <span>Active Product (Month)</span>
                <h3 className="value">0</h3>
              </span>
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <Loader />
          ) : data?.bets?.length === 0 || data === {} ? (
            <div
              style={{
                height: "300px",
              }}
            >
              <h1 style={{ textAlign: "center", marginTop: "5rem" }}>
                NO DATA FOUND, SELECT A DATE.
              </h1>
            </div>
          ) : (
            show && (
              <div className="new-table-box">
                <h5 style={{ fontSize: "1.3rem", marginTop: ".5rem" }}>
                  Month Summary
                </h5>
                <table className="new-table" style={{ marginTop: "-1.4rem" }}>
                  <tr>
                    <th>Settled Date</th>
                    <th>Settled Bets</th>
                    <th>No of Selections</th>
                    <th>Stake (N)</th>
                    <th>Winnings (N)</th>
                    <th>WeightedStake (N)</th>
                  </tr>
                  <>
                    {data?.bets?.map((item, i) => (
                      <tr className="main" key={i}>
                        <td>{item?.date}</td>
                        <td>{item?.settled_bets}</td>
                        <td>{item?.selection_count}</td>
                        <td>{item?.total_stake}</td>
                        <td>{item?.total_winnings}</td>
                        <td>{item?.weighted_stake}</td>
                      </tr>
                    ))}
                    {data && (
                      <tr className="total">
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td>{data?.totalStake}</td>
                        <td>{data?.totalWinnings}</td>
                        <td>{data?.totalWeightedStake}</td>
                      </tr>
                    )}
                  </>
                </table>
              </div>
            )
          )}
        </div>

        {show && data && (
          <div>
            <BonusInformation data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthlyBonus;
