import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyTransaction } from "../Services/apis";
import { AccountMenu } from "../Components/AccountMenu";
import { useSelector } from "react-redux";

const PaymentVerification = ({history}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const {user, isAuthenticated} = useSelector(state => state.auth);
  const [trxRef, setTrxRef] = useState(null); 
  const [message, setMessage] = useState(null); 
  const [success, setSuccess] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const {type: gateway} = useParams();

  useEffect(() => {
    document.body.classList.add("defaultlogged", "Logged");
    document.body.classList.remove("default", "Anonymous");
    if (!isAuthenticated) {
      history.push("/Login");
      document.body.classList.remove("defaultlogged", "Logged");
      document.body.classList.add("default", "Anonymous");
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    switch (gateway) {
      case 'monnify':
        setTrxRef(queryParams.get('paymentReference'))
        break;
      case 'flutterwave':
        setTrxRef(queryParams.get('tx_ref'))
        break;
      case 'paystack':
        setTrxRef(queryParams.get('trxref'))
        break;
      default:
        break;
    }
  }, [gateway]);


  useEffect(() => {
    async function verify(data) {
      await verifyTransaction(data).then(res => {
        setLoading(false);
        if(res.success) {
          setSuccess(true);
          setMessage('Your payment was successful!!')
          setTimeout(() => {
            window.location.href = '/';
          }, [3000]);
        } else {
          setMessage(res.message);
          setSuccess(false);
        }
      }).catch(err => setLoading(false) | setSuccess(false) | setMessage('We are unable to verify payment'));
    }

    if(trxRef) verify({paymentChannel: gateway, trxRef})

  }, [trxRef]);

  return (
    <>
      <AccountMenu componentClase="homeLoggedMenu" user={user} />
      <div className="homeShop logged" style={{paddingTop: 40, textAlign: 'center'}}>
          {loading && 
          <>
            <img src="/img/loader.svg" />
            <h1>Confirming payment...</h1>
          </>}
          {message && 
            <div style={{padding: 20, backgroundColor: success ? 'green' : 'red'}}>
              <h1 style={{color: 'white'}}>{message}</h1>
            </div>
          }
      </div>
    </>
    
  );
};

export default PaymentVerification;
