import React, { Fragment, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccountMenu } from "../Components/AccountMenu";
import { RightSide } from "../Components/RightSide";
import { SportsMenu } from "../Components/SportsMenu";
import { setActivePeriod, setSports } from "../Redux/actions";
import { getSportMenu } from "../Services/apis";
import { periods } from "../Utils/constants";

export default function Sports({ children }) {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const {activePeriod, sports, tournaments} = useSelector((state) => state.sportsData);
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master oddsasync Logged";
  }, []);

  const getSports = async () => {
    await getSportMenu(activePeriod).then(res => {
        dispatch(setSports(res.menu));
    });
  }

  useEffect( () => {
      getSports();
  }, [activePeriod]);


  const doSearch = () => {
    if (keyword.length) history.push(`/Sport/SearchResults?q=${keyword}`);
  };

  return (
    <Fragment>
      <div className="spacer5"></div>
      <table cellPadding="0" cellSpacing="0" id="tblMainContent">
        <tbody>
          <tr>
            <td className="tdSX">
              <div className="SXCercaContent">
                <div className="TitleCerca">
                  <span>Search</span>
                </div>
                <div>
                  <input
                    name="s$w$PC$oddsSearch$txtSearch"
                    type="text"
                    maxlength="50"
                    className="TxtCerca"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyUp={(e) => {
                      if(e.key === 'Enter') doSearch();
                    }}
                  />
                </div>
                <div>
                  <a title="Start search" className="BtnCerca" href="#" onClick={doSearch}></a>
                </div>
              </div>
              <SportsMenu sports={sports} tournaments={tournaments} activePeriod={activePeriod} />

              {isAuthenticated && (
                <AccountMenu componentClase="loggedMenu" user={user} />
              )}
            </td>
            <td className="tdCN">
              <div className="iSBox ctrl_ViewModeSelector">
                <div className="viewModeSelector">
                  <div className="typeVisbutton">
                    <ul className="labelSteps">
                      {periods.map((period, i) => 
                        <li
                          key={period.value}
                          onClick={() => dispatch(setActivePeriod(period.value))}
                          className={`s${i} ${activePeriod === period.value ? 'sel' : ''}`}>
                          <div>{period.label}</div>
                        </li>
                      )}
                        
                    </ul>
                    
                  </div>
                </div>
              </div>
              {children}
            </td>
            <td className="tdDX">
              <RightSide />
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
}
