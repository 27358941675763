import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generate } from "../../Services/apis";
import { cashbooktoday } from "../../Services/apis";
import Modal from "../../Components/Modal";
import { Spinner } from "reactstrap";
/**
 * packages
 */

export const LastApproved = () => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputObject, setObject] = useState({
    totalCommissions: 0,
    totalSales: 0,
    totalTickets: 0,
    totalVbetTickets: 0,
    totalVbetSales: 0,
    totalVbetWinnings: 0,
    totalWinnings: 0,
    balance: 0,
    username: "",
    date: "",
  });
  const { user } = useSelector((state) => state.auth);
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const toggle = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setObject({
      ...inputObject,
      [e.target.name]: e.target.value,
    });
  };

  const generateData = async () => {
    setLoading(true);

    const payload = {
      agent_id: user.id,
      from: firstDay,
      to: lastDay,
    };
    await generate(payload)
      .then((r) => {
        setLoading(false);
        setDetails(r?.data);
      })
      .catch((err) => {
        console.log(err.response);

        setLoading(false);
      });
    toggle();
  };

  useEffect(() => {
    generateData();
  }, []);
  useEffect(() => {
    setObject({
      totalCommissions: details?.totalCommissions,
      totalSales: details?.totalSales,
      totalTickets: details?.totalTickets,
      totalVbetTickets: details?.totalVbetTickets,
      totalVbetSales: details?.totalVbetSales,
      totalVbetWinnings: details?.totalVbetWinnings,
      totalWinnings: details?.totalWinnings,
      balance: details?.user?.balance,
      username: details?.user?.username,
      date: details?.created_at,
    });
  }, [details]);


  console.log(details);

  return (
    <>
      <div className="homeShop last-approved">
        <div className="table">
          <div className="head">
            <h4>Last Approved</h4>
          </div>

          <div className="flex">
            <p>
              <strong>Opening Balance</strong>
            </p>
            <p>{details[0]?.opening_balance}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Normal Sales</strong>
            </p>
            <p>{details[0]?.normal_sales}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Normal Payout</strong>
            </p>
            <p>{details[0]?.normal_payout}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Virtual Sales</strong>
            </p>
            <p>{details[0]?.virtual_sales}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Virtual Payout</strong>
            </p>
            <p>{details[0]?.virtual_payout}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Cash In</strong>
            </p>
            <p>{details[0]?.cash_in}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Cash Out</strong>
            </p>
            <p>{details[0]?.cash_out}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Expenses</strong>
            </p>
            <p>{details[0]?.expenses}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Online Sales</strong>
            </p>
            <p>{details[0]?.expenses}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Online Withdrawal</strong>
            </p>
            <p>{details[0]?.expenses}</p>
          </div>

          <div className="flex">
            <p>
              <strong>Online Balance</strong>
            </p>
            <p>{details[0]?.expenses}</p>
          </div>
          <div className="flex">
            <p>
              <strong>Closing Balance</strong>
            </p>
            <p>{details[0]?.closing_balance}</p>
          </div>
        </div>
      </div>
      <Modal title={"Generate Reports"} modal={modal} toggle={toggle}>
        <form className="new-entry">
          <div className="entry-field">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={inputObject.username}
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Opening Balance</label>
            <input
              type="number"
              onChange={handleChange}
              name="balance"
              value={inputObject?.balance}
              disabled={true}
            />
          </div>
          {/* <div className="entry-field">
            <label>Date</label>
            <input
              type="date"
              name="date"
              value={inputObject?.date}
              disabled={true}
            />
          </div> */}
          <div className="entry-field">
            <label>Total Sales</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalSales}
              name="totalSales"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Tickets</label>
            <input
              type="number"
              onChange={handleChange}
              name="totalTickets"
              value={inputObject?.totalTickets}
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Vbet Sales</label>
            <input
              type="number"
              value={inputObject?.totalVbetSales}
              name="totalVbetSales"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Vbet Tickets</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalVbetTickets}
              name="totalVbetTickets"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Winnings</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalWinnings}
              name="totalWinnings"
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Vbet Winnings</label>
            <input
              type="number"
              onChange={handleChange}
              name="totalVbetWinnings"
              value={inputObject?.totalVbetWinnings}
              disabled={true}
            />
          </div>
          <div className="entry-field">
            <label>Total Commissions</label>
            <input
              type="number"
              onChange={handleChange}
              value={inputObject?.totalCommissions}
              name="totalCommissions"
              disabled={true}
            />
          </div>
          <div className="btn-create">
            <button onClick={toggle}>Close</button>
          </div>
        </form>
      </Modal>
    </>
  );
};
