import React, { useEffect, useState } from "react";
import moment from "moment";
import { getBetList } from "../../Services/apis";
import { formatDate, formatNumber } from "../../Utils/helpers";
import BetListOutcome from "../../Components/BetListOutcome";
import Pagination from "../../Components/Pagination";
import PaidBetListFilter from "../../Components/PaidBetListFilter";
import { SHOW_MODAL } from "../../Redux/types";
import ViewCoupon from "../../Components/Modal/ViewCoupon";
import { useDispatch } from "react-redux";

/**
 * packages
 */

export const BetListPayout = ({ match, history }) => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    from: moment().subtract(1, "w").toDate(),
    to: moment().toDate(),
    period: "",
    status: "",
    betslip_id: "",
    page_size: 15,
  });

  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 2,
    from: 1,
    to: 0,
    current_page: 1,
    last_page: 0,
  });

  const [totalStake, setTotalStake] = useState(0);
  const [totalWinnings, setTotalWinnings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [bets, setBets] = useState([]);

  const fetchBetList = (page) => {
    setLoading(true);
    const data = {
      type: filterData.type,
      from: moment(filterData.from).format("YYYY-MM-DD"),
      to: moment(filterData.to).format("YYYY-MM-DD"),
      page_size: filterData.page_size,
      betslip_id: filterData.betslip_id,
      paid: 1,
    };
    getBetList(data, page)
      .then((res) => {
        setPagination({
          total: res.tickets.total,
          per_page: res.tickets.per_page,
          from: res.tickets.from,
          to: res.tickets.to,
          current_page: res.tickets.current_page,
          last_page: res.tickets.last_page,
        });
        setBets(res.tickets.data);
        setTotalWinnings(res.totalWon);
        setTotalStake(res.totalSales);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    document.body.classList = "bodyMain en-GB account_master betlist Logged";
  }, []);

  useEffect(() => {
    fetchBetList(1);
  }, []);

  const handleChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
  };

  return (
    <div id="MainContent" className="">
      <div className="Riquadro">
        <div className="TopSX">
          <div className="TopDX">
            <h3>Bet List Payout</h3>
          </div>
        </div>
        <div className="CntSX">
          <div className="CntDX">
            <div className="betlist">
              <PaidBetListFilter
                filterData={filterData}
                handleChange={handleChange}
                fetchResult={fetchBetList}
                ticketsLength={bets.length}
              />
              <div className="divDg">
                {/*<div>
                        <table
                            className="dgStyle" cellSpacing="0" border="0"
                            id="ac_w_PC_PC_BetList_grid"
                            style={{
                                borderWidth:'0px',
                                borderStyle:'None',
                                width: '100%',
                                borderCollapse:'collapse'
                            }}>
                            <tbody>
                            <tr className="dgEmptyStyle">
                                <td colSpan="12">
                                    No record found
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>*/}
                <div>
                  <table
                    className="dgStyle"
                    cellSpacing="0"
                    border="0"
                    id="ac_w_PC_PC_grid"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "None",
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr
                        className="dgHdrStyle"
                        style={{
                          borderWidth: "0px",
                          borderStyle: "none",
                          width: "100%",
                          borderCollapse: "collapse",
                          backgroundColor: "#975e01",
                        }}
                      >
                        <th scope="col" style={{ textAlign: "left" }}>
                          Betslip
                        </th>
                        <th scope="col">User</th>
                        <th scope="col">Bet Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Result Date</th>
                        <th className="dgHdrImporti" scope="col">
                          Amount
                        </th>
                        <th scope="col">Outcome</th>
                        <th className="dgHdrImporti" scope="col">
                          Winnings
                        </th>
                      </tr>
                      {loading ? (
                        <tr className="dgItemStyle">
                          <td colSpan="12">Loading...Please wait!</td>
                        </tr>
                      ) : bets.length > 0 ? (
                        bets.map((bet, i) => (
                          <tr className="dgItemStyle" key={bet.betslip_id}>
                            <td className="btnsec codcoupon">
                              <a
                                title="Dislpay Betslip"
                                href="javascript:;"
                                onClick={() =>
                                  dispatch({
                                    type: SHOW_MODAL,
                                    payload: {
                                      open: true,
                                      title: "Betslip " + bet.betslip_id,
                                      component: (
                                        <ViewCoupon
                                          betslip={bet}
                                          dispatch={dispatch}
                                        />
                                      ),
                                    },
                                  })
                                }
                              >
                                {bet.betslip_id}
                              </a>
                            </td>
                            <td>{bet.username}</td>
                            <td>{bet.bet_type}</td>
                            <td>
                              {formatDate(
                                bet.created_at,
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </td>
                            <td>
                              {bet.settled_at
                                ? formatDate(
                                    bet.settled_at,
                                    "DD/MM/YYYY HH:mm:ss"
                                  )
                                : ""}
                            </td>
                            <td align="right">{formatNumber(bet.stake)}</td>
                            <td align="center">
                              <BetListOutcome outcome={bet.status} />
                            </td>
                            <td align="right">
                              {bet.status === 0
                                ? formatNumber(bet.pot_winnings)
                                : formatNumber(bet.winnings)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="dgEmptyStyle">
                          <td colSpan="12">No record found</td>
                        </tr>
                      )}

                      <tr className="dgTotalsStyle">
                        <td
                          className="btnsec"
                          colSpan="5"
                          style={{ width: "100%" }}
                        >
                          - Total Page -
                        </td>
                        <td
                          className="dgTotalsImpPos"
                          colSpan="2"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {formatNumber(totalStake)}
                        </td>
                        <td colSpan="1"></td>
                        <td
                          className="dgTotalsImpNeg"
                          colSpan="1"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {formatNumber(totalWinnings)}
                        </td>
                        <td colSpan="4"></td>
                      </tr>
                      {/*<tr className="dgTotalsStyle">
                                              <td className="btnsec" colSpan="4" style={{width: '100%'}}>- Total -</td>
                                              <td className="dgTotalsImpPos" colSpan="2" style={{whiteSpace:'nowrap'}}></td>
                                              <td colSpan="1"></td>
                                              <td className="dgTotalsImpNeg" colSpan="1" style={{whiteSpace:'nowrap'}}></td>
                                              <td colSpan="4"></td>
                                          </tr>*/}
                      <Pagination
                        colspan={9}
                        data={pagination}
                        offset={10}
                        changePage={fetchBetList}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
