import React from "react";

export const BonusInformation = () => {
  return (
    <div className="bonus-information">
      <table className="bonus-information-table">
        <tr>
          <th>Group</th>
          <th>Avg Selection</th>
          <th>Bonus Rate (GGR Margin {"<"} 80%)</th>
          <th>Bonus Rate (GGR Margin {">="} 80%)</th>
        </tr>
        <tr className="main">
          <td>Group A</td>
          <td>1-3.00</td>
          <td>5%</td>
          <td>5%</td>
        </tr>
        <tr>
          <td>Group B</td>
          <td>3.01-6.00</td>
          <td>30%</td>
          <td>5%</td>
        </tr>
        <tr>
          <td>Group C</td>
          <td>6.01-8.00</td>
          <td>35%</td>
          <td>15%</td>
        </tr>
        <tr>
          <td>Group D</td>
          <td>8.01+</td>
          <td>40%</td>
          <td>20%</td>
        </tr>
      </table>
      <p className="info-note">
        <strong>Important Notice:</strong> The above report has been reviewed to
        match West African Time.
      </p>
      <ul>
        <li>
          <strong>a. The bonus value is given only if:</strong>
        </li>
        <li>i. The total stake for the selected period exceeds 150,000₦.</li>
        <li>ii. The gross profit is not in negative (i.e. it exceeds 0₦).</li>
        <li>
          iii. The total amount of Turnover commission that will be/has been
          paid to you for the selected period is less than the commission %
          amount based on GGR (i.e. X% of GGR minus total Turnover commission
          exceeds 0₦)
        </li>
        <li>
          <strong>b.</strong> If the above 3 conditions are not all met, then
          the report will specify "No Bonus" and you would not be eligible for
          the monthly sports bonus.
        </li>
        <li>
          <strong>Note</strong> Total Estimated Commission is the total
          estimated commission amount of your Sports Turnover Commission for the
          week. For sports periods of prior months, this amount will be 0, as
          all your Turnover Commission would have been paid and is listed in the
          Total Paid Commission box.
        </li>
      </ul>
    </div>
  );
};
