import React from "react";

export const AppFooter = () => {
  return (
    <div id="divFooter">
      <div className="Main">
        <div className="Content">
          <div className="Terms">
            <ul>
              <div className="Logo">
                <a href="/" id="h_w_lnkHP">
                  <img
                    id="h_w_Image1"
                    src="/img/logo.png"
                    style={{ borderWidth: "0px", width: "130px" }}
                    alt="logo"
                  />
                </a>
              </div>
              {/* <div className="Social">
                <a
                  className="facebook"
                  href="https://www.facebook.com/Bet9ja.Official/"
                  target="_blank"
                ></a>
                <a
                  className="twitter"
                  href="https://twitter.com/Bet9jaOfficial"
                  target="_blank"
                ></a>
                <a className="gplus" target="_blank"></a>
                <a
                  className="instagram"
                  href="https://www.instagram.com/bet9jaig/"
                  target="_blank"
                ></a>
              </div> */}
            </ul>
            <ul>
              <li>
                <a title="" href="#"></a>
              </li>
              <li>
                <a title="Apps" href="#" target="_blank">
                  Apps
                </a>
              </li>
              <li>
                <a title="Home" href="/Sport/Default.aspx">
                  Home
                </a>
              </li>
              <li>
                <a title="About Us" href="/Pages/aboutus/Content">
                  About Us
                </a>
              </li>

              <li>
                <a
                  title="Results"
                  onclick="window.open('#,'', 'width=860,height=700,scrollbars=1')"
                >
                  Results
                </a>
              </li>
              <li>
                <a
                  title="Contact Us"
                  href="/TPAutologin.aspx?Destinazione=contact_us_agent"
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  title="Web Affiliates"
                  onclick="window.open('#','', 'width=860,height=700,scrollbars=1')"
                  target="_self"
                >
                  Web Affiliates
                </a>
              </li>
              <li>
                <a title="Become an Agent" onclick="window.open('#','_blank')">
                  Become an Agent
                </a>
              </li>
            </ul>

            <ul>
              <li>
                <a title=""> </a>
              </li>
              <li>
                <a title="Privacy" href="/pages/SportFooter_Privacy/Content">
                  Privacy
                </a>
              </li>
              <li>
                <a
                  title="Sport T&amp;C"
                  href="/pages/SportFooter_SportTerms/Content"
                >
                  Sport T&amp;C
                </a>
              </li>
              <li>
                <a
                  title="Terms and Conditions"
                  href="/pages/SportFooter_Terms/Content"
                >
                  Terms and Conditions
                </a>
              </li>

              <li>
                <a
                  title="Responsible Gaming"
                  href="/pages/SportFooter_RespGaming/Content"
                >
                  Responsible Gaming
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="divBottom">
            <div className="Disclaimer">
              2020 All rights reserved
              <div className="CreditLogo" />
              <span className="eighteen" />
            </div>
          </div> */}
        </div>
        <span className="blueLine" />
      </div>
    </div>
  );
};
