import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { TransferForm } from "../../Components/TransferForm";
import { sendFund } from "../../Services/apis";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getUsers } from "../../Redux/actions/user";
import { UPDATE_USER_BALANCE } from "../../Redux/types";

const FormSchema = Yup.object().shape({
  amount: Yup.number().min(1, "Missing amount").required("Enter an amount"),
  username: Yup.string().required("Please enter a username"),
});

export default function TransferFunds() {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [action, setAction] = useState('deposit');
  const [inputText, setInputText] = useState("");
  const dispatch = useDispatch();
  const { type } = useParams();
  const { shopUsers, getUserLoading, error } = useSelector(
    (state) => state.auth
  );
  const userData = useSelector((state) => state.auth.user);

  let inputHandler = (e) => {
    //convert input text to lower case

    var lowerCase = e.target.value;
    setInputText(e.target.value);
  };

  const filteredData = shopUsers.filter((item) => {
    return item.username.toLowerCase().indexOf(inputText.toLowerCase()) !== -1;
  });

  useEffect(() => {
    document.body.classList = "bodyMain en-GB sport_master user Logged";

    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setUsers(shopUsers);
  }, [shopUsers]);

  const fundUser = (values, { setSubmitting, resetForm }) => {
    sendFund(values)
      .then((res) => {
        setSubmitting(false);
        if (res.success) {
          resetForm({});
          setUser(null);
          toast.success("Transaction was completed successfully");
          dispatch(getUsers());
          dispatch({ type: UPDATE_USER_BALANCE, payload: res.data.balance });
        } else {
          toast.error(res.message);
        }
      })
      .catch(
        (err) => setSubmitting(false) | toast.error("Internal server error!")
      );
  };

  return (
    <>
      <div id="MainContent" className="">
        <div className="Riquadro">
          <div className="TopSX">
            <div className="TopDX">
              <h3>
                {" "}
                Transfer Funds to{" "}
                {type === "internal"
                  ? "Cashier/ Online users"
                  : "Lucky balls Account"}
              </h3>
            </div>
          </div>
          <div
            className="search-box"
            style={{ display: "flex", margin: "1rem auto", width: "50%" }}
          >
            <p>SEARCH</p>
            <input type="text" onChange={inputHandler} name="phone" />
          </div>
          <div className="CntSX">
            <div className="CntDX">
              <div className="payments">
                <div className="RiquadroSrc">
                  <div className="Cnt">
                    <div className="divide-holder funds-flex">
                      <div className="one-half pr5">
                        <table
                          className="dgStyle"
                          cellSpacing="0"
                          border="0"
                          id="ac_w_PC_PC_grid"
                          style={{
                            borderWidth: "0px",
                            borderStyle: "None",
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <tbody>
                            <tr className="dgHdrStyle">
                              <th align="center">Name</th>
                              <th align="center">Balance</th>
                              <th align="center"></th>
                            </tr>
                          </tbody>
                          <tbody>
                            {filteredData &&
                              filteredData?.map((row) => (
                                <tr className="dgItemStyle">
                                  <td align="center" className="userList">
                                    {row?.username}
                                  </td>
                                  <td align="center">
                                    {row?.available_balance}
                                  </td>
                                  <td
                                    align="center"

                                  >
                                    <img
                                      src="/img/SendFastSport2.png"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setAction('withdraw');
                                        setUser(row);
                                      }}
                                    />
                                    <img
                                      src="/img/SendFastSport.png"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setAction('deposit');
                                        setUser(row);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}

                            {shopUsers && shopUsers?.length === 0 && (
                              <tr className="dgItemStyle">
                                <td colSpan="12" align="center">
                                  No result found
                                </td>
                              </tr>
                            )}
                            {getUserLoading && (
                              <tr className="dgItemStyle">
                                <td colSpan="4">Loading...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="one-half ml">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            username: user?.username || "",
                            amount: "",
                            notes:
                              type === "internal"
                                ? ""
                                : `Credit to Elbet user ${userData?.username}  `,
                            type: type === "internal" ? "internal" : "elbet",
                            action,
                          }}
                          validationSchema={FormSchema}
                          children={(props) => <TransferForm {...props} />}
                          onSubmit={fundUser}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
