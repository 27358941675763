import React from "react";
import { formatNumber } from "../Utils/helpers";
import { ExportCSV } from "./Download";

const AllSalesTable = ({ data, currency }) => {
  return (
    <div className="table-bottom sales-table">
      <table
        className="new-table"
        style={{
          width: "100%",
        }}
      >
        <tr
          style={{
            borderWidth: "0px",
            width: "100%",
            // borderCollapse: "collapse",
            border: "none",
            background: "transparent",
            color: "#4c4c4c",
            textAlign: "center",
          }}
        >
          <th align="center" scope="col" style={{ padding: ".7rem" }}>
            Channel Name
          </th>
          <th align="center" scope="col">
            # of Bets
          </th>
          <th align="center" scope="col">
            Running Bets
          </th>
          <th align="center" scope="col">
            Settled Bets
          </th>
          <th align="center" scope="col">
            Stake Bets ({currency})
          </th>
          <th align="center" scope="col">
            Winnings ({currency})
          </th>
        </tr>
        {/* <tbody> */}
        {/* {data.map((item, index) => ( */}
        <React.Fragment>
          <tr className="dgItm">
            <td align="center" style={{ textTransform: "uppercase" }}>
              RETAIL SPORTS
            </td>
            <td align="center">
              {data?.retail_sports?.no_of_bets
                ? data?.retail_sports?.no_of_bets
                : 0}
            </td>
            <td align="center">
              {data?.retail_sports?.running_bets
                ? data?.retail_sports?.running_bets
                : 0}
            </td>
            <td align="center">
              {data?.retail_sports?.settled_bets
                ? data?.retail_sports?.settled_bets
                : 0}
            </td>
            <td align="center">
              {data?.retail_sports?.stake
                ? formatNumber(data?.retail_sports?.stake)
                : 0}
            </td>
            <td align="center">
              {data?.retail_sports?.winnings
                ? formatNumber(data?.retail_sports?.winnings)
                : 0}
            </td>
          </tr>
          <tr className="dgItm">
            <td align="center" style={{ textTransform: "uppercase" }}>
              RETAIL VIRTUAL
            </td>
            <td align="center">
              {data?.retail_virtual?.no_of_bets
                ? data?.retail_virtual?.no_of_bets
                : 0}
            </td>
            <td align="center">
              {data?.retail_virtual?.running_bets
                ? data?.retail_virtual?.running_bets
                : 0}
            </td>
            <td align="center">
              {data?.retail_virtual?.settled_bets
                ? data?.retail_virtual?.settled_bets
                : 0}
            </td>
            <td align="center">
              {data?.retail_virtual?.stake
                ? formatNumber(data?.retail_virtual?.stake)
                : 0}
            </td>
            <td align="center">
              {data?.retail_virtual?.winnings
                ? formatNumber(data?.retail_virtual?.winnings)
                : 0}
            </td>
          </tr>
        </React.Fragment>
        {/* ))} */}
        <tr className="total">
          <td align="center" style={{ textTransform: "uppercase" }}>
            Total
          </td>
          <td align="center">{data?.grand_total?.no_of_bets}</td>
          <td align="center">{data?.grand_total?.running_bets}</td>
          <td align="center">{data?.grand_total?.settled_bets}</td>
          <td align="center">{formatNumber(data?.grand_total?.stake)}</td>
          <td align="center">{formatNumber(data?.grand_total?.winnings)}</td>
        </tr>
      </table>
      <div
        className="date-group "
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
          paddingTop: "2rem",
          borderTop: "1px solid #ff2222",
        }}
      >
        <ExportCSV csvData={data} fileName="All Sales" file="PDF" />
        <ExportCSV csvData={data} fileName="All Sales" file="CSV" />
      </div>
    </div>
  );
};

export default AllSalesTable;
