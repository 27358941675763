import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

export const BonusTypeCard = () => {
  const history = useHistory();

  return (
    <div className="bonus-type-box">
      <div className="bonus-type-row">
        <div className="bonus-type-card-box">
          <div className="bonus-type-card">
            <div className="bonus-card-icon">
              <h2>...</h2>
            </div>
            <p className="card-title">COMING SOON</p>
            <hr />
            <p>TURNOVER FOR THE WEEK</p>
          </div>
          <div className="bonus-btn">
            WEEKLY BONUS
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                fontSize: "15",
                color: "red",
                float: "right",
              }}
            />
          </div>
        </div>
        <div className="bonus-type-card-box">
          <div className="bonus-type-card">
            <div className="bonus-card-icon">
              <h2>...</h2>
            </div>
            <p className="card-title">IN PROGRESS</p>
            <hr />
            <p>TURNOVER FOR THE MONTH</p>
          </div>
          <div
            className="bonus-btn"
            onClick={() => history.push("/Account/Monthly-Bonus")}
          >
            POWER BONUS{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                fontSize: "15",
                color: "red",
                float: "right",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
