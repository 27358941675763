import Paystack from "../Static/img/Paystack.png";
import Flutter from "../Static/img/flutterwave.png";
import Monnify from "../Static/img/monnify.png";
export const bankDetail = [
  {
    name: "Paystack",
    id: "2",
    detail: "(Card bank)Instant Credit",
    logo: Paystack,
    slug: "paystack",
  },
  {
    name: "Flutterwave",
    id: "1",
    detail: "(Card bank)Instant Credit",
    logo: Flutter,
    slug: "rave",
  },
  {
    name: "Monnify",
    id: "3",
    detail: "(Card bank)Instant Credit",
    logo: Monnify,
    slug: "monnify",
  },
];
